import React from 'react';
import { formatNumber, formatNumberIntl } from '@digital-gov/ui-utils';
import s from './SymbolDetails.module.scss';

export interface SymbolDetailsProps {
  value?: number | null;
  roundUp?: boolean;
  roundDecimals?: number;
  unit?: string;
  description: React.ReactNode;
  children: React.ReactNode;
}

export function SymbolDetails({ value, roundUp, roundDecimals, unit, description, children }: SymbolDetailsProps) {
  return (
    <div>
      <div className={s.SymbolDetails__pair}>
        {children}
        <div className={s.SymbolDetails__value}>
          <span>{roundUp ? formatNumberIntl(Math.floor(value ?? 0)) : formatNumber(value ?? 0, roundDecimals)}</span>
          {unit && <span>{unit === '%' ? `${unit}` : ` ${unit}`}</span>}
        </div>
      </div>

      <p className={s.SymbolDetails__description}>{description}</p>
    </div>
  );
}
