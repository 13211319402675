import { memo } from 'react';
import { formatNumber } from '@digital-gov/ui-utils';
import { RegionType } from 'store/graphql';
import { PositionDynamic } from '../../PositionDynamic';
import { ProgressDynamic } from '../../ProgressDynamic';
import { POINTS_LAST_ID } from '../../RegionsFilters';
import { TableCellProgress } from '../TableCellProgress';
import { TableCellRatingGroup } from '../TableCellRatingGroup';
import { TableCellText } from '../TableCellText';
import { RegionsTableHeaderFields, SelectedIndicatorType } from '../types';

export interface TableCellFactoryProps {
  header: RegionsTableHeaderFields;
  cellData: RegionType;
  className?: string;
  selectedIndicator?: SelectedIndicatorType | null;
}

export const TableCellFactory = memo((props: TableCellFactoryProps) => {
  const { header, cellData, className, selectedIndicator } = props;
  let Component = null;

  const sharedProps = {
    className
  };

  switch (header) {
    case RegionsTableHeaderFields.ratingPosition:
      Component = (
        <TableCellText
          {...sharedProps}
          title={String(cellData.ratingPosition)}
          rightComponent={
            <PositionDynamic
              ratingPosition={cellData.ratingPosition}
              previousRatingPosition={cellData.previousRatingPosition}
            />
          }
          titleVariant={'xs'}
        />
      );
      break;
    case RegionsTableHeaderFields.representatives:
      Component = (
        <TableCellRatingGroup
          {...sharedProps}
          title={cellData.representatives[0]?.fullName}
          ratingGroup={cellData.ratingGroup}
        />
      );
      break;
    case RegionsTableHeaderFields.regionName:
      Component = (
        <TableCellText
          {...sharedProps}
          current={cellData.isCurrentRegion}
          ratingGroup={cellData.ratingGroup}
          title={cellData.regionName}
          subTitle={cellData.regionShortName}
        />
      );
      break;
    case RegionsTableHeaderFields.rrct:
      Component = (
        <TableCellText
          {...sharedProps}
          title={cellData.rrct[0]?.fullName}
          subTitle={String(cellData.rrct[0]?.year ?? '')}
        />
      );
      break;
    case RegionsTableHeaderFields.degree:
      Component = <TableCellProgress {...sharedProps} degree={cellData.degree} ratingGroup={cellData.ratingGroup} />;
      break;
    case RegionsTableHeaderFields.indicatorsValues:
      let indicatorValue: number | null = null;
      if (selectedIndicator?.indicatorId === POINTS_LAST_ID) {
        indicatorValue = cellData.pointsLast ?? null;
      } else {
        indicatorValue =
          cellData.indicatorsValues.find(({ indicatorId }) => indicatorId === selectedIndicator?.indicatorId)
            ?.scoreForLastPeriod ?? null;
      }

      const rightComponent = selectedIndicator?.indicatorId === POINTS_LAST_ID && (
        <ProgressDynamic dynamic={cellData.dynamic} degree={cellData.degree} />
      );

      Component = (
        <TableCellText
          {...sharedProps}
          title={formatNumber(indicatorValue)}
          titleVariant={'m'}
          rightComponent={rightComponent}
        />
      );
      break;
  }

  return Component;
});

TableCellFactory.displayName = 'TableCellFactory';
