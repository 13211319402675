import React, { useMemo } from 'react';
import clsx from 'clsx';
import { groupBy, orderBy } from 'lodash-es';
import { BaseCard } from 'componentsL/BaseCard';
import { Loader } from 'componentsL/Loader';
import { ChartHeader } from './ChartHeader/ChartHeader';
import { DepartmentChartProvider } from './ChartProvider/DepartmentChartProvider';
import { useDepartmentChartContext } from './ChartProvider/useDepartmentChartContext';
import { ChartTable } from './ChartTable/ChartTable';
import { ChartTableTotal } from './ChartTableTotal/ChartTableTotal';
import { departmentChartMinHeight, ratingGroups } from './constants';
import { RatingDataGroupsType, RatingDataType, RatingOrgType } from './types';
import s from './DepartmentChart.module.scss';

document.documentElement.style.setProperty('--dep-chart-table-height', `${departmentChartMinHeight}px`);

function convertData(data?: RatingOrgType[]): RatingDataType[] {
  if (!data?.length) return [];
  return orderBy(
    data.map((r) => ({
      ...r,
      vpctSum: r.vpctSum * 1000,
      completenessGroup: r.ratingGroup,
      budgetGroup: r.budgetGroupIndex
    })),
    ['degree', 'departmentName'],
    ['desc', 'asc']
  );
}

export interface DepartmentChartProps {
  data?: RatingOrgType[];
  budgetGroups?: [number, number][];
  loading?: boolean;
}

const DepartmentChartComp = ({ data, budgetGroups, loading }: DepartmentChartProps) => {
  const { group } = useDepartmentChartContext();

  const ratingsData: RatingDataType[] = convertData(data);
  const _budgetGroups: [number, number][] = budgetGroups ?? [];

  const dataGroups = useMemo(() => {
    return Object.fromEntries(
      ratingGroups.map((level) => {
        const cGroupedItems = ratingsData.filter((item) => item.ratingGroup === level);
        const bgItems: Record<number, RatingDataType[]> = groupBy(cGroupedItems, (item) => item.budgetGroup);
        return [level, bgItems];
      })
    ) as RatingDataGroupsType;
  }, [ratingsData]);

  return (
    <BaseCard
      className={clsx(s.DepartmentChart, {
        [s.DepartmentChart_centered]: loading || !data?.length
      })}>
      {loading ? (
        <Loader />
      ) : !data?.length ? (
        'Нет данных'
      ) : (
        <>
          {group ? (
            <ChartTable
              data={dataGroups[group.completenessGroup][group.budgetGroup]}
              budgetGroups={_budgetGroups}
              {...group}
            />
          ) : (
            <ChartTableTotal dataGroups={dataGroups} budgetGroups={_budgetGroups} />
          )}
        </>
      )}
    </BaseCard>
  );
};

export interface DepartmentChartCommonProps {
  departments?: RatingOrgType[];
  budgetGroups?: [number, number][];
  loading?: boolean;
}

export const DepartmentChart = ({ departments, loading, budgetGroups }: DepartmentChartCommonProps) => {
  const viceChairmen = useMemo(() => {
    if (!departments?.length) return [];
    const vices: string[] = [];
    departments.forEach(({ viceChairmans }) => {
      const uniqViceChairmans = viceChairmans.filter((v) => !vices.includes(v.fullName)).map((v) => v.fullName);
      vices.push(...uniqViceChairmans);
    });
    return vices;
  }, [departments]);

  return (
    <DepartmentChartProvider>
      <ChartHeader availableViceChairmen={viceChairmen} />
      <DepartmentChartComp data={departments} budgetGroups={budgetGroups} loading={loading} />
    </DepartmentChartProvider>
  );
};
