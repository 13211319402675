import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ChartTick } from '../_components/ChartTick/ChartTick';
import { useDepartmentChartContext } from '../ChartProvider/useDepartmentChartContext';
import { ratingGroups } from '../constants';
import { DepartmentChartUtils } from '../DepartmentChartUtils';
import { RatingDataGroupsType } from '../types';
import { ChartCellData } from './ChartCellData';
import { getDepartmentsTableLimitations } from './tableLimitations';
import { DataCellSize } from './tableLimitations.types';
import s from './ChartTableTotal.module.scss';

export interface ChartTableTotalProps {
  dataGroups: RatingDataGroupsType;
  budgetGroups: [number, number][];
}

export const ChartTableTotal = ({ dataGroups, budgetGroups }: ChartTableTotalProps) => {
  const { setGroup } = useDepartmentChartContext();

  const limitations: Map<string, DataCellSize> = useMemo(() => {
    const { sizes } = getDepartmentsTableLimitations(dataGroups, undefined, budgetGroups);
    return sizes;
  }, [dataGroups, budgetGroups]);

  return (
    <table className={s.ChartTableTotal}>
      <tbody>
        {ratingGroups.map((completeness, i) => {
          const data = dataGroups[completeness];

          return (
            <tr key={completeness}>
              {i === 0 && (
                <th className={s.ChartRow__cell_head} rowSpan={ratingGroups.length}>
                  <ChartTick
                    className={clsx(s.ChartRow__tableTitle, s.ChartRow__tableTitle_aside, s.ChartRow__asideTick)}>
                    Эффективность
                  </ChartTick>
                </th>
              )}
              <th className={s.ChartRow__cell_head}>
                <ChartTick className={clsx(s.ChartRow__asideTick)}>
                  <div>{DepartmentChartUtils.ratingCompletenessLabels[completeness]}</div>
                </ChartTick>
              </th>
              {budgetGroups.map((group, iGroup) => {
                return (
                  <td
                    key={iGroup}
                    className={clsx(s.ChartRow__cell_data, s[`ChartRow__cell_${completeness.toLowerCase()}`])}>
                    {data?.[iGroup] && (
                      <div style={{ minWidth: `${limitations?.get(`${completeness}_${iGroup}`)?.columnWidth ?? 0}px` }}>
                        <ChartCellData
                          elementsPerColumn={limitations?.get(`${completeness}_${iGroup}`)?.elementsPerColumn ?? 10}
                          data={data?.[iGroup]}
                          onGroupEnterClick={() => setGroup({ completenessGroup: completeness, budgetGroup: iGroup })}
                        />
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <th className={s.ChartRow__cell_head} />
          <th className={s.ChartRow__cell_head} />
          {budgetGroups.map((group, iGroup) => {
            return (
              <td key={iGroup}>
                <ChartTick className={s.ChartRow__tick}>{DepartmentChartUtils.getBudgetGroupLabel(group)}</ChartTick>
              </td>
            );
          })}
        </tr>
        <tr className={clsx(s.ChartRow, s.ChartTotalRow)}>
          <th className={s.ChartRow__cell_head} />
          <th className={s.ChartRow__cell_head} />
          <th colSpan={budgetGroups.length}>
            <ChartTick className={clsx(s.ChartRow__tableTitle, s.ChartRow__tableTitle_bottom)}>
              Финансирование ВПЦТ 2024
            </ChartTick>
          </th>
        </tr>
      </tbody>
    </table>
  );
};
