import React from 'react';
import { EMDASH, formatNumber, formatNumberIntl } from '@digital-gov/ui-utils';
import { DynamicValue } from 'components/DynamicValue';
import { Comments } from 'features/Comments';
import { CommentDraftType, CommentType } from 'features/Comments/_utils/types';
import { RatingCommentScopeEnum } from 'store/graphql';
import { EfficiencyGroup, EfficiencyGroupLine } from './Efficiency';
import { Person } from './Person';
import { StatisticsBlockItem } from './StatisticsBlockItem';
import {
  DegreeText,
  DynamicFromPreviousText,
  EfficiencyGroupText,
  PointsCountText,
  PointsMaxText,
  PointsTotalText,
  RepresentativeText
} from './StatisticsBlockTexts';
import s from './StatisticsBlock.module.scss';

/**
 * Пропсы используемые в фиче "Комментарии"
 */
export interface StatisticsBlockCommentsProps {
  scope: RatingCommentScopeEnum;
  scopeId: number;
  scopeName?: string | null;
  scopeIndicatorId?: number | null;
  draft?: CommentDraftType | null;
  comments?: CommentType[] | null;
  refetch?: () => void;
}

export interface StatisticsBlockProps {
  // общие пропсы для ФОИВ-а и РОИВ-а
  efficiencyGroup: 'High' | 'Average' | 'Low';
  efficiencyGroupText: string;
  degree?: number | null;
  pointsTotal?: number | null;
  pointsMax?: number | null;
  pointsDynamic?: number | null;
  lastPeriodDate?: string | null;
  indicatorsCount?: number | null;
  // только для ФОИВ-а
  frct?: string | null;
  viceChairman?: string | null;
  // только для РОИВ-а
  rrct?: string | null;
  representative?: string | null;
  // блок комментариев
  commentsControl?: StatisticsBlockCommentsProps;
}

export function StatisticsBlock({
  efficiencyGroup,
  efficiencyGroupText,
  degree,
  pointsTotal,
  pointsMax,
  pointsDynamic,
  lastPeriodDate,
  indicatorsCount,
  frct,
  rrct,
  viceChairman,
  representative,
  commentsControl
}: StatisticsBlockProps) {
  return (
    <div className={s.StatisticBlock}>
      <div className={s.StatisticBlock__items}>
        <EfficiencyGroupLine group={efficiencyGroup} />
        <StatisticsBlockItem
          label={<EfficiencyGroupText />}
          content={<EfficiencyGroup group={efficiencyGroup} label={efficiencyGroupText} />}
        />
        <StatisticsBlockItem label={<DegreeText />} content={`${formatNumber(degree)}`} />
        <StatisticsBlockItem label={<PointsTotalText />} content={formatNumber(pointsTotal)} />
        <StatisticsBlockItem label={<PointsMaxText />} content={`${formatNumber(pointsMax)}`} />
        <StatisticsBlockItem
          label={<DynamicFromPreviousText lastPeriodDate={lastPeriodDate} />}
          content={
            pointsDynamic ? <DynamicValue dynamic={pointsDynamic} withArrow withSymbol formatted softColors /> : EMDASH
          }
        />
        {indicatorsCount != null && (
          <StatisticsBlockItem label={<PointsCountText />} content={`${formatNumberIntl(indicatorsCount)}`} />
        )}
        {/* ФРЦТ */}
        {frct && <StatisticsBlockItem label={'ФРЦТ'} content={<Person person={frct} formatToInitials={true} />} />}
        {/* Вице-премьер */}
        {viceChairman && (
          <StatisticsBlockItem
            label={'Вице-премьер'}
            content={<Person person={viceChairman} formatToInitials={true} />}
          />
        )}
        {/* РРЦТ для пользователя*/}
        {rrct && <StatisticsBlockItem label={'РРЦТ'} content={<Person person={rrct} formatToInitials={true} />} />}
        {/* Полномочный представитель */}
        {representative && (
          <StatisticsBlockItem
            label={<RepresentativeText />}
            content={<Person person={representative} formatToInitials={true} />}
          />
        )}
        {/*  Полномочный представитель для пользователя */}
      </div>
      {commentsControl && (
        <Comments
          scope={commentsControl.scope}
          scopeId={commentsControl.scopeId}
          scopeName={commentsControl.scopeName}
          comments={commentsControl.comments}
          draft={commentsControl.draft}
          departmentRefetch={
            commentsControl.scope === RatingCommentScopeEnum.Department ? commentsControl.refetch : undefined
          }
          regionRefetch={commentsControl.scope === RatingCommentScopeEnum.Region ? commentsControl.refetch : undefined}
        />
      )}
    </div>
  );
}
