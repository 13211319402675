import React from 'react';
import { DATE_FULL_DAY_SHORT, formatDate } from '@digital-gov/ui-utils';
import { PageError } from 'components/PageError';
import { Button, ButtonVariant } from 'componentsL/Button';
import { ExcelLoader } from 'componentsL/ExcelLoader';
import { IconDownload } from 'componentsL/Icon';
import { Loader } from 'componentsL/Loader';
import { PageHeader } from 'componentsL/PageHeader';
import { ScopeSwitch } from 'componentsL/ScopeSwitch';
import { ScopeEnum } from 'componentsL/ScopeSwitch/ScopeSwitch';
import { ExcelGenerator } from 'features/ExcelGenerator';
import { DepartmentIndicatorsCriteriaType, ExcelDataSourceEnum, useDepartmentsPageQuery } from 'store/graphql';
import { DepartmentsChart } from './DepartmentsChart';
import s from './DepartmentsPage.module.scss';

const checkBudgetGroups = (
  criteria: Pick<
    DepartmentIndicatorsCriteriaType,
    'group1Min' | 'group1Max' | 'group2Min' | 'group2Max' | 'group3Min' | 'group3Max' | 'group4Min'
  >
): boolean => {
  // проверка, что с бэка (ФГИС-КИ) идут нормальные данные
  const { group1Min, group1Max, group2Min, group2Max, group3Min, group3Max, group4Min } = criteria;
  const valuesAsIs = [group1Min, group1Max, group2Min, group2Max, group3Min, group3Max, group4Min];
  const orderedValues = valuesAsIs.slice().sort((a, b) => a - b);
  if (Math.min(...valuesAsIs) !== group1Min) return false;
  if (Math.max(...valuesAsIs) !== group4Min) return false;
  return valuesAsIs.toString() === orderedValues.toString();
};

export function DepartmentsPage() {
  const { data, loading } = useDepartmentsPageQuery();
  const departments = data?.rating.departmentRating.departments;
  const criteria = data?.rating.departmentRating.criteria;

  if (!criteria && loading) {
    return <Loader />;
  }

  if (criteria && !checkBudgetGroups(criteria)) {
    return <PageError />;
  }

  const budgetGroups: [number, number][] = criteria
    ? [
        [criteria.group1Min * 1000, criteria.group1Max * 1000],
        [criteria.group2Min * 1000, criteria.group2Max * 1000],
        [criteria.group3Min * 1000, criteria.group3Max * 1000],
        [criteria.group4Min * 1000, Infinity]
      ]
    : [];

  const lastSubmitDate = data?.rating.departmentRating.lastUpdateDate;

  return (
    <>
      <PageHeader
        className={s.DepartmentsPageHeader__root}
        title={'Оперативный рейтинг ФРЦТ'}
        slotRight={
          <div className={s.DepartmentsPageHeader}>
            {lastSubmitDate && (
              <p className={s.DepartmentsPageHeader__date}>
                Обновление: {formatDate(lastSubmitDate, DATE_FULL_DAY_SHORT)}
              </p>
            )}
            <ExcelGenerator dataSource={ExcelDataSourceEnum.DepartmentsChart}>
              {({ download, loading }) =>
                loading ? (
                  <ExcelLoader />
                ) : (
                  <div className={s.DepartmentsPageHeader__excel}>
                    <Button
                      Component={'a'}
                      variant={ButtonVariant.LinkPrimary}
                      iconLeft={IconDownload}
                      onClick={download}
                      download>
                      Скачать
                    </Button>
                  </div>
                )
              }
            </ExcelGenerator>
            <ScopeSwitch scope={ScopeEnum.Frct} />
          </div>
        }
      />
      <DepartmentsChart
        departments={departments?.map((d) => ({
          ...d,
          rct: d.frct[0]?.fullName
        }))}
        budgetGroups={budgetGroups}
        loading={loading}
      />
    </>
  );
}
