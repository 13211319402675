import React from 'react';
import clsx from 'clsx';
import { Icon, IconDismiss } from 'componentsL/Icon';
import { ChartTick } from '../_components/ChartTick/ChartTick';
import { useDepartmentChartContext } from '../ChartProvider/useDepartmentChartContext';
import { DepartmentChartUtils } from '../DepartmentChartUtils';
import { ChartData, ChartDataProps } from './ChartData';
import s from './ChartTable.module.scss';

export interface ChartTableProps extends ChartDataProps {}

export const ChartTable = ({ data, budgetGroup, budgetGroups, completenessGroup }: ChartTableProps) => {
  const { setGroup } = useDepartmentChartContext();

  return (
    <div className={s.ChartTable__container}>
      <Icon
        className={s.ChartTable__closeButton}
        icon={IconDismiss}
        size={36}
        iconSize={24}
        onClick={() => setGroup(null)}
      />

      <table className={s.ChartTable}>
        <tbody>
          <tr>
            <th className={s.ChartRow__cell_head}>
              <ChartTick className={clsx(s.ChartRow__tableTitle, s.ChartRow__tableTitle_aside, s.ChartRow__asideTick)}>
                Эффективность
              </ChartTick>
            </th>
            <th className={s.ChartRow__cell_head}>
              <ChartTick className={s.ChartRow__asideTick}>
                {DepartmentChartUtils.ratingCompletenessLabels[completenessGroup]}
              </ChartTick>
            </th>
            <td className={clsx(s.ChartTable__cell_data, s[`ChartTable__cell_${completenessGroup.toLowerCase()}`])}>
              <table className={s.LinesTable}>
                <tbody>
                  <tr>
                    <td className={s.LinesTable__cell} />
                  </tr>
                  <tr>
                    <td className={s.LinesTable__cell} />
                  </tr>
                </tbody>
              </table>
              {!!data?.length && (
                <ChartData
                  data={data}
                  budgetGroup={budgetGroup}
                  budgetGroups={budgetGroups}
                  completenessGroup={completenessGroup}
                />
              )}
            </td>
          </tr>
          <tr>
            <th className={s.ChartRow__cell_head} />
            <th className={s.ChartRow__cell_head} />
            <td>
              <ChartTick className={s.ChartRow__tick}>
                {DepartmentChartUtils.getBudgetGroupLabel(budgetGroups[budgetGroup])}
              </ChartTick>
            </td>
          </tr>
          <tr>
            <th className={s.ChartRow__cell_head} />
            <th className={s.ChartRow__cell_head} />
            <th>
              <ChartTick className={clsx(s.ChartRow__tableTitle, s.ChartRow__tableTitle_bottom)}>
                Финансирование ВПЦТ 2024
              </ChartTick>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
