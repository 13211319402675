import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { RatingItem, RatingItemContentNodesType, RatingLabelPosition } from '../_components/RatingItem/RatingItem';
import { useDepartmentChartContext } from '../ChartProvider/useDepartmentChartContext';
import { DepartmentChartUtils } from '../DepartmentChartUtils';
import { RatingDataType, RatingGroupType } from '../types';
import s from './ChartTable.module.scss';

export interface ChartDataProps extends RatingGroupType {
  data: RatingDataType[];
  budgetGroups: [number, number][];
}

type ItemRefType = { id: number; nodes: RatingItemContentNodesType };

const ratingItemLabelPositions = [
  RatingLabelPosition.Right,
  RatingLabelPosition.Left,
  RatingLabelPosition.Bottom,
  RatingLabelPosition.Top
];

function checkIfNodesIntersect(currentNode: HTMLElement, otherNode: HTMLElement, offset: number = 0): boolean {
  if (!currentNode || !otherNode) return false;
  const currNodeBounds = currentNode.getBoundingClientRect();
  const otherNodeBounds = otherNode.getBoundingClientRect();

  return (
    currNodeBounds.top <= otherNodeBounds.top + otherNodeBounds.height - offset &&
    currNodeBounds.top + currNodeBounds.height - offset >= otherNodeBounds.top &&
    currNodeBounds.left <= otherNodeBounds.left + otherNodeBounds.width - offset &&
    currNodeBounds.left + currNodeBounds.width - offset >= otherNodeBounds.left
  );
}

export const ChartData = ({ data, budgetGroup, budgetGroups }: ChartDataProps) => {
  const itemsRef = useRef<ItemRefType[]>([]);
  const [labelPositions, setLabelPositions] = useState<{ [key: number]: RatingLabelPosition }>({});
  const labelPositionChangeCountRef = useRef(100);
  const labelPositionChangeTO = useRef<NodeJS.Timeout>();
  const [ready, setReady] = useState(false);
  const dataLengthRef = useRef(data.length || 0);

  const { searchValue, viceChairman } = useDepartmentChartContext();
  const ticks = DepartmentChartUtils.getChartTicksByValues(data);
  const tickMin = ticks[0];
  const tickMax = ticks[ticks.length - 1];

  const budgetValues = budgetGroups[budgetGroup];
  const budgetMin = budgetValues[0];
  const budgetMax =
    budgetValues[1] === Infinity ? Math.ceil(Math.max(...data.map((item) => item.vpctSum))) : budgetValues[1];

  const handleNodes = (id: number, nodes: RatingItemContentNodesType) => {
    if (!itemsRef.current.find((item) => item.id === id)) {
      itemsRef.current.push({
        id,
        nodes
      });
    }
  };

  const checkIntersection = useCallback(() => {
    const intersectedItems: ItemRefType[] = [];

    /**
     * проходим по всему списку элементов
     */
    itemsRef.current.forEach((currItem, iItem, items) => {
      if (currItem.nodes.labelNode) {
        /**
         * проверяем, пересекается ли элемент с остальными
         */
        items.forEach((item) => {
          if (item.id !== currItem.id && item.nodes.labelNode && item.nodes.dotNode) {
            if (
              checkIfNodesIntersect(currItem.nodes.labelNode, item.nodes.labelNode, 3) ||
              checkIfNodesIntersect(currItem.nodes.labelNode, item.nodes.dotNode, 3)
            ) {
              /**
               * записываем в массив оба переспекающихся элемента, если их в массиве еще нет
               */
              if (!intersectedItems.some((intItem) => intItem.id === currItem.id)) {
                intersectedItems.push(currItem);
              }
              if (!intersectedItems.some((intItem) => intItem.id === item.id)) {
                intersectedItems.push(item);
              }
            }
          }
        });
      }
    });

    return intersectedItems;
  }, []);

  const updateLabelsPosition = useCallback(() => {
    if (!itemsRef.current.length || dataLengthRef.current > itemsRef.current.length) return;

    try {
      if (labelPositionChangeCountRef.current <= 0) {
        throw new Error();
      }

      const intersectedItems = checkIntersection();
      if (!intersectedItems.length) {
        throw new Error();
      }

      setLabelPositions((prevState) => {
        /**
         * берем массив пересекающихся элементов, которые еще не меняли положение
         */
        const notMovedItems = intersectedItems.filter((item) => !prevState[item.id]);
        /**
         * иначе берем все элементы, если все уже меняли положение
         */
        const itemsToMove = notMovedItems.length ? notMovedItems : intersectedItems;
        /**
         * рандомно выбираем элемент массива
         */
        const item = itemsToMove[Math.floor(Math.random() * itemsToMove.length)];
        /**
         * проставляем новую позицию:
         * если позиция уже менялась, то берем след. из списка,
         * иначе вторую, т.к. первая у элемента стоит по дефолту
         */
        const itemPosition = prevState[item.id];
        const posIndex = ratingItemLabelPositions.findIndex((p) => p === itemPosition);
        const nextPos =
          ratingItemLabelPositions[
            posIndex < 1 ? 1 : posIndex === ratingItemLabelPositions.length - 1 ? 0 : posIndex + 1
          ];

        return {
          ...prevState,
          [item.id]: nextPos
        };
      });

      labelPositionChangeCountRef.current--;
    } catch (e) {
      setReady(true);
    }
  }, [checkIntersection]);

  useLayoutEffect(() => {
    clearTimeout(labelPositionChangeTO.current);
    labelPositionChangeTO.current = setTimeout(() => {
      updateLabelsPosition();
    }, 0);
    return () => clearTimeout(labelPositionChangeTO.current);
  }, [updateLabelsPosition, labelPositions]);

  return (
    <div
      className={clsx(s.ChartData, {
        [s.ChartData_ready]: ready
      })}>
      {orderBy(data, ['degree'], ['desc']).map((item) => {
        const style = {
          top: `${100 - ((item.degree - tickMin) / (tickMax - tickMin)) * 100}%`,
          left: `${((item.vpctSum - budgetMin) / (budgetMax - budgetMin)) * 100}%`
        };

        return (
          <div key={item.departmentId} className={s.ChartData__item} style={style}>
            <RatingItem
              absolute
              label={item.departmentName}
              labelPosition={labelPositions[item.departmentId]}
              filtered={DepartmentChartUtils.checkItemIsFiltered(item, searchValue, viceChairman)}
              onMount={(nodes) => handleNodes(item.departmentId, nodes)}
              {...item}
            />
          </div>
        );
      })}
    </div>
  );
};
