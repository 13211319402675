import React from 'react';
import { EMDASH } from '@digital-gov/ui-utils';
import { IconButton } from 'components/Button';
import { Mail } from 'components/Icon';
import s from './PersonResponsible.module.scss';

export function PersonResponsible({ responsibles }: { responsibles: { fullName: string; email?: string | null }[] }) {
  const renderMailIcon = (email: string) => <IconButton href={`mailto:${email}`} icon={Mail} />;

  return (
    <section className={s.PersonResponsible}>
      <div className={s.PersonResponsible__control}>
        <div className={s.PersonResponsible__content}>
          <h4 className={s.PersonResponsible__heading}>Ответственное лицо</h4>

          {!responsibles.length && <div className={s.PersonResponsible__person}>{EMDASH}</div>}
          {responsibles.map(({ fullName, email }, index) => (
            <div key={`${index}-${email}`} className={s.PersonResponsible__person}>
              <span>
                {fullName || EMDASH}
                {fullName && responsibles.length !== index + 1 && ','}
              </span>
              {!!email && renderMailIcon(email)}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
