import React from 'react';
import { IndicatorPage, UpdateDays } from '../../_components/IndicatorPage';
import { Symbol } from '../../_components/Symbol';
import { SymbolDetails } from '../../_components/SymbolDetails';
import { SymbolsGrid } from '../../_components/SymbolsGrid';
import { Table } from '../../_components/Table';
import { TableRow } from '../../_components/TableRow';
import { IndicatorComponentsProps } from '../../_utils/types';
import { ReactComponent as Formula } from './formula.svg';

function IndicatorFormula() {
  return <Formula />;
}

function IndicatorComponents({ components }: IndicatorComponentsProps) {
  const cashUtilizationShare = components[0]?.value;
  const limitsCashExecution = components[1]?.value;
  const approvedLimits = components[2]?.value;

  return (
    <SymbolsGrid columns={3}>
      <SymbolDetails
        value={cashUtilizationShare}
        unit={'%'}
        description={
          <>
            % кассового освоения расходных обязательств ведомства <br />в части реализации мероприятий по информатизации
          </>
        }>
        <Symbol sub={'242, 246'}>О</Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={limitsCashExecution}
        unit={'тыс. руб.'}
        description={'Кассовое исполнение доведенных лимитов бюджетных обязательств'}>
        <Symbol sub={'242, 246'} up={'ИСП'}>
          К
        </Symbol>
      </SymbolDetails>
      <SymbolDetails
        value={approvedLimits}
        unit={'тыс. руб.'}
        description={'Утвержденные лимиты бюджетных обязательств'}>
        <Symbol sub={'242, 246'}>ЛБО</Symbol>
      </SymbolDetails>
    </SymbolsGrid>
  );
}

function IndicatorTable() {
  return (
    <Table>
      <TableRow values={['Оценка, балл', 'Значение']} isHeader />
      <TableRow values={['0', '0-19,9 %']} />
      <TableRow values={['0,5', '20,0-39,9 %']} />
      <TableRow values={['1,0', '40,0-59,9 %']} />
      <TableRow values={['1,5', '60,0-79,9 %']} />
      <TableRow values={['2,0', '80,0-100,0 %']} />
    </Table>
  );
}

export function CashExecution() {
  return (
    <IndicatorPage
      download={'Кассовое исполнение.pdf'}
      formula={IndicatorFormula}
      indicatorComponents={IndicatorComponents}
      indicatorTable={IndicatorTable}
      updateDays={UpdateDays.InFirstAnd15}
    />
  );
}
